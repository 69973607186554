/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'

import Card from '../card'
import CenterCol from '../center-col'
import Section from '../section'
import Newsletter from '.'

const NewsletterSection = (props) => {
  return (
    <Section
      inset={false}
    >
      <CenterCol>
        <Card expandPadding={true}>
          <Newsletter />
        </Card>
      </CenterCol>
    </Section>
  )
}

export default NewsletterSection
