/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction } from 'lodash'
import { graphql } from 'gatsby'

import Button from '../components/button'
import { HomeHeader } from '../components/page-header'
import PageWrapper from '../components/page-wrapper'
import WhatsOn from '../components/whats-on-section'
import Buttons from '../components/buttons'
import {
  MemberStoriesCardSection,
  NewsCardSection,
  SocialCardSection,
} from '../components/card-section'
import Section from '../components/section'
import CardRow from '../components/card-row'
import ImageFill from '../components/image'
import PastProductionsCta from '../components/past-productions-cta'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  VStack,
} from '../styles/els'

import { formatImage, renderHTML, resolveButtons } from '../lib/helpers'
import Logo from '../components/logo'
import { useThemeUISetValue } from '../styles/utils'
import NewsletterSection from '../components/newsletter/section'

const Involved = (props) => {
  const { data, pageColor } = props
  const gI = get(data, 'page.acfTemplateHomepage.getInvolved')
  const heading = get(gI, 'heading')
  const text = get(gI, 'text')
  const _cards = get(gI, 'cards')
  const cards =
    _cards && _cards.length > 0
      ? _cards.map((_c, _i) => {
        const targetPage = get(_c, 'card.0')
        if (!targetPage) {
          return null
        }

        const heading = get(targetPage, 'title')
        const link = { to: get(targetPage, 'uri') }

        const linkedPageColor = get(targetPage, 'acfPage.pageColour')
        const buttonColor = linkedPageColor ? linkedPageColor : pageColor

        const image = targetPage ? get(targetPage, 'acfPage.thumbnail') : null

        return {
          heading,
          link,
          buttonColor,
          image: image ? formatImage(image) : null,
        }
      })
      : null

  return (
    <Section inset={true} bg="grey4">
      <FlexCols>
        <FlexCol across={[3, null, null, 1]} base={3}>
          {heading || text ? (
            <VStack>
              {heading ? (
                <h4 sx={{ variant: 'text.heading_36', color: 'white' }}>
                  {renderHTML(heading)}
                </h4>
              ) : null}
              {text ? (
                <div sx={{ variant: 'textBlock.25' }}>{renderHTML(text)}</div>
              ) : null}
            </VStack>
          ) : null}
        </FlexCol>
        <FlexCol across={[3, null, null, 2]} base={3}>
          <CardRow
            cards={cards}
            greyBg={true}
            buttonPrimary={true}
            forceNarrowRatio={true}
          />
        </FlexCol>
      </FlexCols>
    </Section>
  )
}

const Stats = (props) => {
  const { data, pageColor } = props
  const { theme } = useThemeUI()
  const cardPadding = useThemeUISetValue('cardPadding', theme)

  const supportUs = get(data, 'page.acfTemplateHomepage.supportUs')
  const heading = get(supportUs, 'heading')
  const text = get(supportUs, 'text')
  const buttons = resolveButtons(get(supportUs, 'buttons'))
  const stats = get(supportUs, 'stats')
  const image = formatImage(get(supportUs, 'bgImage'))

  return supportUs ? (
    <Section inset={true} bgEl={image ? <ImageFill image={image} /> : null}>
      <div
        sx={{
          display: ['block', null, null, 'flex'],
          alignItems: 'stretch',
          backgroundColor: 'grey4',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <div
          sx={{
            p: cardPadding,
            width: ['100%', null, null, '33.33%'],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flex: ['none', null, null, 1],
          }}
        >
          {heading || text ? (
            <VStack>
              {heading ? (
                <h4 sx={{ variant: 'text.heading_36', color: pageColor }}>
                  {renderHTML(heading)}
                </h4>
              ) : null}
              {text ? (
                <div sx={{ variant: 'textBlock.25' }}>{renderHTML(text)}</div>
              ) : null}
            </VStack>
          ) : null}
          {buttons && buttons.length > 0 ? (
            <Buttons
              buttons={buttons}
              pageColor={pageColor}
              sx={{ mt: text || heading ? cardPadding : null }}
            />
          ) : null}
        </div>
        <div
          sx={{
            position: 'relative',
            width: ['100%', null, null, '66.66%'],
            backgroundColor: 'grey3',
            flex: ['none', null, null, 2],
            p: cardPadding,
          }}
        >
          <FlexCols space={4}>
            {stats && stats.length > 0
              ? stats.map((_s, _i) => {
                return (
                  <FlexCol base={[1, 2]} key={_i}>
                    {_s.value ? (
                      <div sx={{ variant: 'text.heading_64' }}>
                        {_s.value}
                      </div>
                    ) : null}
                    {_s.label ? (
                      <div sx={{ color: 'grey1' }}>{_s.label}</div>
                    ) : null}
                  </FlexCol>
                )
              })
              : null}
          </FlexCols>
        </div>
      </div>
    </Section>
  ) : null
}

const Homepage = (props) => {
  const { data } = props

  const { theme } = useThemeUI()

  const pT = useThemeUISetValue('headingPadding', theme)

  const pageColor = get(data, 'page.acfPage.pageColour')

  const video = get(data, 'page.acfTemplateHomepage.video')
  const videoPlaceholder = formatImage(
    get(data, 'page.acfTemplateHomepage.videoPlaceholder')
  )
  const tagline = get(data, 'page.acfTemplateHomepage.tagline')

  return (
    <PageWrapper>
      <HomeHeader
        video={video}
        videoPlaceholder={videoPlaceholder}
        tagline={tagline}
      />
      <Space space={pT} />
      <NewsletterSection />
      <WhatsOn />
      <Involved {...props} pageColor={pageColor} />
      <MemberStoriesCardSection />
      <Stats {...props} pageColor={pageColor} />
      <PastProductionsCta />
      <NewsCardSection />
      <SocialCardSection />
    </PageWrapper>
  )
}

export default Homepage

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      template {
        templateName
      }
      acfPage {
        pageColour
        headerImage {
          ...FullsizeImage
        }
      }
      acfTemplateHomepage {
        video
        videoPlaceholder {
          ...InlineImage
        }
        tagline
        getInvolved {
          heading
          text
          cards {
            card {
              ... on WpPage {
                acfPage {
                  pageColour
                  thumbnail {
                    ...InlineImage
                  }
                }
                title
                uri
              }
            }
          }
        }
        supportUs {
          bgImage {
            ...FullsizeImage
          }
          stats {
            value
            label
          }
          heading
          text
          buttons {
            label
            type
            linksTo
            externalLink
            email
            internalLink {
              __typename
              ... on WpPage {
                title
                uri
                acfPage {
                  pageColour
                }
              }
            }
          }
        }
        news {
          image {
            ...InlineImage
          }
          date
          title
          description
        }
      }
    }
    newsPage: allWpPage(
      filter: { template: { templateName: { eq: "News" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
  }
`
